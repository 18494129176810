<template>
  <div class="examine_detail_container">
    <div class="header">
      <van-image width="110"
                 height="110"
                 src="https://peis.ycrmyy.net/wxxcx/wx/tj_package.jpg"
                 fit="cover"></van-image>
      <div class="content">
        <div class="title">驾驶员体检</div>
        <div class="info">套餐简介：</div>
        <div class="num">
          <van-tag plain
                   type="primary">1个项目</van-tag>
        </div>
        <div class="price">
          <span>&yen;30.00</span>
          <s>&yen;30.00</s>
        </div>
      </div>
    </div>
    <div class="tips">
      <van-icon name="info-o"
                color="orange"
                size="20px" />
      <span>温馨提示：套餐购买后暂不支持线上取消退款，仅支持在医院收费处窗口办理退费</span>
    </div>
    <div class="note">
      <div class="title">
        <van-icon name="warning-o"
                  color="skyblue"
                  size="20px"
                  class="left" />
        <span>注意事项</span>
        <van-icon name="arrow-down"
                  size="20px"
                  class="right"
                  v-if="!more"
                  @click="showMore" />
        <van-icon name="arrow-up"
                  size="20px"
                  class="right"
                  v-if="more"
                  @click="showMore" />
      </div>
      <div class="content"
           v-if="more">
        注意事项注意事项注意事项注意事项注意事项注意事项
      </div>
    </div>
    <div class="time">
      <van-icon name="notes-o"
                color="skyblue"
                size="20px" />
      <span>请选择到检时间&nbsp;&nbsp;{{timeSlot}}</span>
    </div>
    <div class="time_list">
      <div class="item"
           :class="item.checked ? 'checked':''"
           v-for="(item,index) in jsonDay"
           :key="index"
           @click="choose"
           :id="index">
        <div>{{item.year}}</div>
        <div>{{item.month}}.{{item.day}}</div>
        <div>{{item.week}}</div>
        <div>余号500</div>
      </div>
    </div>
    <van-tabs v-model="active">
      <van-tab title="检测项目">
        <div class="title">
          <van-icon name="star-o" />
          <span>检测项目</span>
          <van-icon name="star-o" />
        </div>
        <div class="content">
          <div class="label">1项</div>
          <div class="info"
               v-for="(item,index) in 10"
               :key="index">
            <div>驾驶员体检</div>
            <div class="desc">驾驶员体检</div>
          </div>

        </div>
      </van-tab>
      <van-tab title="体检须知">
        <div class="title">
          <van-icon name="star-o" />
          <span>体检须知</span>
          <van-icon name="star-o" />
        </div>
        <div class="content">暂无内容</div>
      </van-tab>
    </van-tabs>
    <van-button type="info"
                block
                class="order"
                @click="order">立&nbsp;刻&nbsp;预&nbsp;约</van-button>
    <!-- 弹出层 -->
    <van-overlay :show="popupShow">
      <div class="wrapper">
        <div class="block">
          <div class="top">
            <van-field readonly
                       clickable
                       label="选择体检人"
                       :value="patName"
                       @click="patNamePickerShow = true"
                       right-icon="arrow-down"
                       class="picker" />
            <van-popup v-model="patNamePickerShow"
                       round
                       position="bottom">
              <van-picker show-toolbar
                          :columns="patNameList"
                          @cancel="patNamePickerShow = false"
                          @confirm="patNameConfirm" />
              <van-button type="info"
                          block
                          class="add"
                          @click="addPatience">添&nbsp;加&nbsp;体&nbsp;检&nbsp;人</van-button>
            </van-popup>
            <van-field label="体检卡号"
                       :value="patCardNo"
                       readonly
                       class="input" />
          </div>
          <div class="btn">
            <van-button type="info"
                        @click="cancel">取&nbsp;&nbsp;消</van-button>
            <van-button type="primary"
                        @click="confirm">确&nbsp;&nbsp;定</van-button>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import { jsonPost, formPost } from "@/baseAPI";
import qs from "qs";
export default {
  name: "Detail",
  data () {
    return {
      more: true,
      jsonDay: [],
      timeSlot: "",
      active: 0,
      popupShow: false,
      patName: "",
      patNamePickerShow: false,
      patNameList: [],
      patNameArray: [],
      patNameIndex: 0,
      patCardNo: ""
    };
  },
  created () {
    for (let i = 0; i <= 6; i++) {
      let obj = {};
      let day = this.getWeek(i);
      let week = this.formatWeek(this.getWeek(i));
      let arr = day.split(".");
      obj.year = arr[0];
      obj.month = arr[1];
      obj.day = arr[2];
      obj.week = week;
      obj.checked = false;
      this.jsonDay.push(obj);
    }
  },
  methods: {
    //获取建档人列表
    async getMembers () {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      let data = qs.stringify({
        openId: window.localStorage.getItem("openId"),
      });
      const { data: res } = await formPost(
        "/wxapp/userBindRecord/userBindRecord/getAllUseInfo",
        data
      );
      this.$toast.clear();
      if (res.code === 0) {
        this.patNameArray = res.data;
        for (let i = 0; i < this.patNameArray.length; i++) {
          this.patNameList.push(res.data[i].name);
        }
        this.patName = this.patNameList[this.patNameIndex];
        this.patCardNo = this.patNameArray[this.patNameIndex].pat_card_no;
      } else {
        this.$toast.fail(res.msg);
        setTimeout(() => {
          this.$router.push({
            path: "/file"
          });
        }, 2000);
      }
    },
    getWeek (num) {
      let now = new Date();
      let nowTime = now.getTime();
      let oneDayTime = 24 * 60 * 60 * 1000;
      let ShowTime = nowTime + num * oneDayTime;
      let myDate = new Date(ShowTime);
      let y = myDate.getFullYear(); //年
      let m =
        myDate.getMonth() + 1 < 10
          ? "0" + (myDate.getMonth() + 1)
          : myDate.getMonth() + 1; //月
      let d = myDate.getDate() < 10 ? "0" + myDate.getDate() : myDate.getDate(); //日
      return [y, m, d].join(".");
    },
    formatWeek (date) {
      let weekArray = new Array(
        "周日",
        "周一",
        "周二",
        "周三",
        "周四",
        "周五",
        "周六"
      );
      let week = weekArray[new Date(date).getDay()];
      return week;
    },
    showMore () {
      this.more = !this.more;
    },
    patNameConfirm (value) {
      this.patName = value;
      this.patNameIndex = this.patNameList.findIndex(item => {
        return item === value;
      });
      this.patCardNo = this.patNameArray[this.patNameIndex].pat_card_no;
      this.patNamePickerShow = false;
    },
    choose (e) {
      let index = parseInt(e.currentTarget.id);
      for (let i = 0; i < this.jsonDay.length; i++) {
        this.jsonDay[i].checked = false;
      }
      this.jsonDay[index].checked = !this.jsonDay[index].checked;
      this.timeSlot =
        this.jsonDay[index].year +
        "-" +
        this.jsonDay[index].month +
        "-" +
        this.jsonDay[index].day;
    },
    order () {
      if (this.timeSlot === "") {
        this.$toast("请选择到检时间");
      } else {
        this.popupShow = true;
        this.getMembers();
      }
    },
    cancel () {
      this.popupShow = false;
      this.patName = "";
      this.patNameList = [];
      this.patNameArray = [];
      this.patNameIndex = 0;
      this.patCardNo = "";
    },
    confirm () {
      this.popupShow = false;
      this.patName = "";
      this.patNameList = [];
      this.patNameArray = [];
      this.patNameIndex = 0;
      this.patCardNo = "";
      this.$router.push({
        path: "/examine/questionnaire"
      });
    },
    addPatience () {
      this.$router.push({
        path: "/file"
      });
    }
  }
};
</script>
<style scope>
.examine_detail_container .header {
  position: sticky;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 9;
  display: flex;
  padding: 20px;
  border-bottom: 1px solid #eee;
}
.examine_detail_container .header .content {
  margin-left: 10px;
  flex: 1;
}
.examine_detail_container .header .title {
  font-size: 40px;
  margin-top: 10px;
}
.examine_detail_container .header .info {
  font-size: 36px;
  color: #bbb3b3;
  margin-top: 10px;
  line-height: 60px;
}
.examine_detail_container .header .num {
  margin: 20px 0;
  font-size: 36px;
}
.examine_detail_container .header .num .van-tag {
  font-size: 30px;
  height: 50px;
}
.examine_detail_container .header .price {
  font-size: 40px;
  color: red;
}
.examine_detail_container .header .price s {
  color: rgb(187, 179, 179);
  margin-left: 10px;
}
.examine_detail_container .tips {
  display: flex;
  font-size: 32px;
  line-height: 50px;
  padding: 10px 20px;
  border-bottom: 1px solid #eee;
}
.examine_detail_container .tips span {
  margin-left: 10px;
}
.examine_detail_container .tips .van-icon {
  transform: translateY(2px);
}
.examine_detail_container .note .title {
  display: flex;
  font-size: 32px;
  padding: 20px;
  border-bottom: 1px solid #eee;
}
.examine_detail_container .note .title .right {
  text-align: right;
  margin-right: 10px;
}
.examine_detail_container .note .title span {
  margin-left: 10px;
  flex: 1;
}
.examine_detail_container .note .content {
  font-size: 32px;
  padding: 10px 20px;
  border-bottom: 1px solid #eee;
  line-height: 54px;
}
.examine_detail_container .time {
  display: flex;
  font-size: 36px;
  padding: 20px;
  border-bottom: 1px solid #eee;
}
.examine_detail_container .time .van-icon {
  transform: translateY(4px);
}
.examine_detail_container .time span {
  margin-left: 10px;
}
.examine_detail_container .time_list {
  display: flex;
  height: 160px;
  background-color: #eee;
  justify-content: space-around;
  align-items: center;
}
.examine_detail_container .time_list .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 130px;
  width: 90px;
  font-size: 20px;
  background-color: white;
  border-radius: 10px;
}
.examine_detail_container .time_list .checked {
  background-color: skyblue;
  color: white;
}
.examine_detail_container .order {
  position: fixed;
  bottom: 60px;
  left: 0;
  z-index: 1;
  font-size: 40px;
  border-radius: 0;
}
.examine_detail_container .van-tab {
  font-size: 36px;
  color: #000;
}
.examine_detail_container .van-tab--active {
  color: #6495ed;
}
.examine_detail_container .van-tabs__line {
  background-color: #6495ed;
  width: 30%;
}
.examine_detail_container .van-tabs__content .title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  padding: 20px 0;
}
.examine_detail_container .van-tabs__content {
  background-color: #eee;
  padding-bottom: 180px;
}
.examine_detail_container .van-tabs__content .title span {
  margin: 0 20px;
}
.examine_detail_container .van-tabs__content .content {
  position: relative;
  background-color: white;
  margin: 0 30px;
  font-size: 36px;
  padding: 20px 30px;
}
.examine_detail_container .van-tabs__content .content .label {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #1989fa;
  color: white;
  width: 80px;
  height: 40px;
  font-size: 26px;
  text-align: center;
  line-height: 40px;
  border-bottom-left-radius: 20px;
}
.examine_detail_container .van-tabs__content .content .desc {
  background-color: #eee;
  font-size: 34px;
  color: gray;
  line-height: 60px;
  margin: 20px 0;
  border-radius: 10px;
  padding: 0 10px;
}
.examine_detail_container .van-overlay {
  z-index: 99;
}
.examine_detail_container .van-overlay .wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.examine_detail_container .van-overlay .wrapper .block {
  padding: 30px 0;
  background-color: #fff;
  width: 90%;
  border-radius: 10px;
  margin-top: -30%;
}
.examine_detail_container .van-overlay .wrapper .block .top {
  border: 2px solid rgb(194, 190, 190);
  margin: 20px 30px;
  border-radius: 10px;
  font-size: 40px;
  overflow: hidden;
}
.examine_detail_container .picker {
  font-size: 40px;
}
.examine_detail_container .add {
  margin-bottom: 60px;
}
.examine_detail_container .picker .van-field__label,
.examine_detail_container .input .van-field__label {
  text-align: left;
  font-size: 40px;
}
.examine_detail_container .picker .van-field__control {
  text-align: right;
  padding-right: 20px;
  font-size: 40px;
}
.examine_detail_container .input .van-field__control {
  text-align: right;
  font-size: 40px;
}
.examine_detail_container .van-picker__cancel,
.examine_detail_container .van-picker__confirm {
  font-size: 40px;
  padding: 40px;
}
.examine_detail_container .block .btn {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
}
.examine_detail_container .block .btn .van-button {
  font-size: 36px;
}
.examine_detail_container .block .van-cell::after {
  border: 0;
}
.examine_detail_container .block .van-icon {
  position: absolute;
  top: 0;
  right: -10px;
  font-size: 40px !important;
}
.examine_detail_container .block .van-picker-column__item {
  font-size: 40px;
}
</style>